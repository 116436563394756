const parseObjToBodyString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
};

let REACT_APP_VERSION_TYPE = process.env.REACT_APP_VERSION_TYPE;

const getAuthHeaders = () => {
  const user = localStorage.getItem("user");
  const userObj = JSON.parse(user);

  if (
    (userObj?.username === "hjmkay@gmail.com" ||
      userObj?.username === "jiojio7@gmail.com") &&
    REACT_APP_VERSION_TYPE === "stable"
  ) {
    return {
      "x-version": "canary",
    };
  } else {
    return { "x-version": REACT_APP_VERSION_TYPE };
  }
};

const api = {};

api.get = (endpoint, query = {}) => {
  let isJson = true;

  const URL = `${
    process.env.REACT_APP_API_URL
  }${endpoint}?${parseObjToBodyString(query)}`;
  return fetch(URL, {
    credentials: "include",
    mode: "cors",
    headers: {
      ...getAuthHeaders(),
    },
  })
    .then((res) => {
      var contentType = res.headers.get("content-type");
      if (contentType.includes("openxmlformats")) {
        isJson = false;
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((res) => {
      if (!isJson) {
        return res;
      }

      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.post = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      ...getAuthHeaders(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.put = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      ...getAuthHeaders(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.putFile = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body,
    credentials: "include",
    headers: {
      ...getAuthHeaders(),
    },
    method: "PUT",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.delete = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      ...getAuthHeaders(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

export default api;

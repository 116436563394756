import Allocations from "@/pages/Allocations";
import Cses from "@/pages/Cses";
import Deliveries from "@/pages/Deliveries";
import DeliveriesKurly from "@/pages/DeliveriesKurly";
import DeliveriesCjthemarket from "@/pages/DeliveriesCjthemarket";
import DeliveriesMonthly from "@/pages/DeliveriesMonthly";
import DeliveriesPickups from "@/pages/DeliveriesPickups";
import DeliveriesReturns from "@/pages/DeliveriesReturns";
import DeliveriesMisclassification from "@/pages/DeliveriesMisclassification";
import Login from "@/pages/Login";
import Riders from "@/pages/Riders";

export const INDEX_PUBLIC_ROUTE_PATHNAME = "/";
export const INDEX_PRIVATE_ROUTE_PATHNAME = "/deliveries";

const routes = [
  {
    component: Allocations,
    exact: true,
    fluid: true,
    name: "Allocations",
    nav: true,
    path: "/allocations",
    private: true,
    rider: true,
  },
  {
    component: Cses,
    exact: true,
    fluid: true,
    name: "Cses",
    nav: true,
    path: "/cses",
    private: true,
  },
  {
    component: Deliveries,
    exact: true,
    fluid: true,
    name: "Deliveries",
    nav: true,
    path: "/deliveries",
    private: true,
  },
  {
    component: DeliveriesMonthly,
    exact: true,
    fluid: true,
    name: "DeliveriesMonthly",
    nav: true,
    path: "/deliveries-monthly",
    private: true,
  },
  {
    component: DeliveriesKurly,
    exact: true,
    fluid: true,
    name: "DeliveriesKurly",
    nav: true,
    path: "/deliveries-kurly",
    private: true,
  },
  {
    component: DeliveriesCjthemarket,
    exact: true,
    fluid: true,
    name: "DeliveriesCjthemarket",
    nav: true,
    path: "/deliveries-cjthemarket",
    private: true,
  },
  {
    component: DeliveriesMisclassification,
    exact: true,
    fluid: true,
    name: "DeliveriesMisclassification",
    nav: true,
    path: "/deliveries-misclassification",
    private: true,
  },
  {
    component: DeliveriesPickups,
    exact: true,
    fluid: true,
    name: "DeliveriesPickups",
    nav: true,
    path: "/deliveries-pickups",
    private: true,
  },
  {
    component: DeliveriesReturns,
    exact: true,
    fluid: true,
    name: "DeliveriesReturns",
    nav: true,
    path: "/deliveries-returns",
    private: true,
  },
  {
    component: Login,
    exact: true,
    name: "Login",
    path: "/",
  },
  {
    component: Riders,
    exact: true,
    fluid: true,
    name: "Riders",
    nav: true,
    path: "/riders",
    private: true,
  },
];

export default routes;

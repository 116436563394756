import React, { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import styled from "@emotion/styled";

import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { Delivery, DELIVERY_TYPE_PICKUP } from "@/Models/Delivery";
import { DeliveryStatusLog } from "@/Models";

import { WaybillModal } from "@/Modals";

import {
  useDelayedDeliveriesStore,
  useDeliveriesStore,
  useModalStore,
} from "@/store/hooks";

const Td = styled.td`
  width: calc(100% - 200px);
  padding: 0.5rem !important;
`;

const Th = styled.th`
  width: 200px;
  padding: 0.5rem !important;
`;

const Title = styled.h3`
  margin: 0;
`;
const SubTitle = styled.h6`
  margin-top: 10px;
  font-weight: bold;
`;

const FlexSpaceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogsWrap = styled.div``;
const LogItem = styled.div``;

const renderBookId = (bookId, type) => {
  switch (type) {
    case DELIVERY_TYPE_PICKUP:
      return `(픽) ${bookId}`;
    default:
      return bookId;
  }
};

const DeliveryForRiderModal = ({ bookId, ...props }) => {
  const { openModal, closeModal } = useModalStore();
  const { ...delayedDeliveriesActions } = useDelayedDeliveriesStore();
  const { ...actions } = useDeliveriesStore();

  useEffect(() => {
    fetchById();
    fetchDeliveryStatusLogs();
  }, []);

  const handleClickPrint = useCallback((deliveries) => {
    openModal(<WaybillModal key="waybill-modal" deliveries={deliveries} />);
  }, []);

  const [rowsDefault, setRowsDefault] = useState([]);
  const [rowsSender, setRowsSender] = useState([]);
  const [rowsReceiver, setRowsReceiver] = useState([]);
  const [rowsProduct, setRowsProduct] = useState([]);
  const [rowsTracking, setRowsTracking] = useState([]);
  const [rowsLog, setRowsLog] = useState([]);

  const fetchById = useCallback(async () => {
    try {
      const _delivery = await actions.fetchByIdOnTheFly(bookId);
      const delayedDeliveries =
        await delayedDeliveriesActions.fetchLatestOneByBookId(bookId);

      const delivery = new Delivery(_delivery);

      let _rowsDefault = [
        {
          header: "예약번호 (배송상태)",
          data: (
            <FlexSpaceWrap>
              <div>
                {renderBookId(delivery.bookId, delivery.type)} (
                {delivery.renderStatus()}
                {delivery.cjOrderIdFromCorpFromOliveyoung
                  ? ", CJ대한통운 배송"
                  : ""}
                )
                {delivery.deliveryRiderName
                  ? ` [${delivery.deliveryRiderName}${
                      delivery.orderingNumberByDeliveryRiderId
                        ? ` ${delivery.orderingNumberByDeliveryRiderId}`
                        : ""
                    }]`
                  : ""}
              </div>
              <Button
                onClick={() => handleClickPrint([_delivery])}
                variant="secondary"
              >
                운송장 다운로드
              </Button>
            </FlexSpaceWrap>
          ),
        },
        {
          header: "사측 주문번호",
          data: delivery.orderIdFromCorp,
        },
        {
          header: "접수일시",
          data: `${formatDate(
            getDateByTimeZone(delivery.receiptDate),
            "MM/dd HH:mm",
          )}${
            delivery.requestedDeliveryTime
              ? ` (배송요청일시: ${delivery.renderRequestedDeliveryTime()})`
              : ""
          }`,
        },
        {
          header: "접수점",
          data: delivery.spot ? delivery.spot.name : "",
        },
        {
          header: "접수점 연락처",
          data: delivery.spot ? delivery.spot.contact : "",
        },
        {
          header: "접수점 주소",
          data: delivery.spot ? delivery.spot.address : "",
        },
      ];

      let _rowsSender = [
        {
          header: "송하인명",
          data: delivery.senderName,
        },
        {
          header: "송하인 연락처",
          data: delivery.senderMobile2,
        },
        {
          header: "송하인 연락처2",
          data: delivery.senderMobile2,
        },
        {
          header: "지역그룹/동",
          data: `${delivery.senderAllocationGroupNameOnWeekdays}(주중) / ${delivery.senderAllocationGroupNameOnWeekends}(주말)`,
        },
        {
          header: "송하인 우편번호",
          data: delivery.senderPostalCode || delivery.senderAddressPostalCode,
        },
        {
          header: "송하인 주소(지번)",
          data: delivery.senderAddress,
        },
        {
          header: "송하인 주소(도로명)",
          data: delivery.senderAddressRoad,
        },
        {
          header: "송하인 상세주소",
          data: delivery.senderAddressDetail,
        },
        {
          header: "위치(위도, 경도)",
          data: `${delivery.senderLnglat.latitude}, ${delivery.senderLnglat.longitude}`,
        },
      ];

      let _rowsReceiver = [
        {
          header: "수하인명",
          data: delivery.receiverName,
        },
        {
          header: "수하인 연락처",
          data: delivery.receiverMobile,
        },
        {
          header: "수하인 연락처2",
          data: delivery.receiverMobile2,
        },
        {
          header: "수하인 우편번호",
          data: delivery.receiverAddressPostalCode,
        },
        {
          header: "수하인 주소(지번)",
          data: delivery.receiverAddress,
        },
        {
          header: "수하인 주소(도로명)",
          data: delivery.receiverAddressRoad,
        },
        {
          header: "수하인 아파트동",
          data: delivery.receiverBuildingDongNumber || "없음",
        },
        {
          header: "수하인 상세주소",
          data: delivery.receiverAddressDetail,
        },
        {
          header: "위치(위도, 경도)",
          data: `${delivery.receiverLnglat.latitude}, ${delivery.receiverLnglat.longitude}`,
        },
        {
          header: "주소정제 API",
          data: delivery.renderAddressApiType,
        },
        {
          header: "고객입력 우편번호",
          data: delivery.customerAddressPostalCode,
        },
        {
          header: "고객 입력 주소",
          data:
            delivery.unrefinedAddress ||
            `${delivery.customerAddress || ""} ${
              delivery.customerAddressDetail || ""
            }`,
        },
      ];

      let _rowsProduct = [
        {
          header: "물품 분류",
          data: delivery.productCategory,
        },
        {
          header: "물품명",
          data: delivery.productName,
        },
        {
          header: "물품 가격",
          data:
            new Intl.NumberFormat({
              style: "currency",
              currency: "KRW",
            }).format(delivery.productPrice) + "원",
        },
        {
          header: "수령희망장소",
          data: delivery.deliveryPlace
            ? `(${formatDate(
                getDateByTimeZone(delivery.deliveryPlacedTime || new Date()),
                "HH:mm",
              )})${delivery.deliveryPlace}`
            : "",
        },
      ];
      let _rowsTracking = [
        {
          header: "취소여부",
          data: delivery.isCanceled ? "O" : "X",
        },
        {
          header: "취소날짜",
          data: delivery.renderCanceledAt(),
        },
        {
          header: "취소사유",
          data: delivery.canceledReason,
        },
        {
          header: "사고여부",
          data: delivery.isAccident ? "O" : "X",
        },
        {
          header: "사고날짜",
          data: delivery.renderAccidentAt(),
        },
        {
          header: "사고사유",
          data: delivery.accidentReason,
        },
        {
          header: "기타사항1",
          data: delivery.etc1,
        },
        {
          header: "기타사항2",
          data: delivery.etc2,
        },
        {
          header: "기타사항3",
          data: delivery.etc3,
        },
        {
          header: "수취인 서명 이미지",
          data: delivery.signImageLocation && (
            <a
              href={delivery.signImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={delivery.signImageLocation} width="100%" />
            </a>
          ),
        },
        {
          header: "미수취 촬영 이미지",
          data: delivery.notReceivedImageLocation && (
            <a
              href={delivery.notReceivedImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={delivery.notReceivedImageLocation}
                width="100%"
              />
            </a>
          ),
        },
        {
          header: "배완사유",
          data: delivery.renderManualCompletedDeliveryReason,
        },
        {
          header: "미배송",
          data: delayedDeliveries.length
            ? `사유: ${delayedDeliveries[0].reason}\n일시: ${formatDate(
                new Date(delayedDeliveries[0].createdAt),
                "yyyy-MM-dd HH:mm",
              )}`
            : "",
        },
        {
          header: "반송완료",
          data: delivery.sentBackLogs.length
            ? delivery.sentBackLogs
                .map(
                  (dd) =>
                    `${formatDate(
                      getDateByTimeZone(dd.createdAt),
                      "MM/dd HH:mm",
                    )} : ${dd.reason}`,
                )
                .join("\n")
            : "",
        },
        {
          header: "분실완료",
          data: `${delivery.renderDeliveryLostDate() || ""} ${
            delivery.lostReason
          }`,
        },
      ];

      if (delivery.pickupCompletedImage) {
        _rowsTracking.push({
          header: "반품수거 촬영 이미지",
          data: delivery.pickupCompletedImage && (
            <a
              href={delivery.pickupCompletedImage}
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={delivery.pickupCompletedImage} width="100%" />
            </a>
          ),
        });
      }

      if (delivery.deliveryDoneLatitude && delivery.deliveryDoneLongitude) {
        _rowsTracking.push({
          header: "배송완료 위치(위경도)",
          data: `${delivery.deliveryDoneLatitude}, ${delivery.deliveryDoneLongitude}`,
        });
      }

      if (delivery.completedLocation) {
        _rowsTracking.push({
          header: "배송완료 위치(장소) 및 안내 문자발송 여부",
          data: `${delivery.completedLocation} / ${
            delivery.sendedCompletedSms ? "발송완료" : "미발송"
          }`,
        });
      }

      if (
        delivery.deliverySentBackLatitude &&
        delivery.deliverySentBackLongitude
      ) {
        _rowsTracking.push({
          header: "반송완료 위치(위경도)",
          data: `${delivery.deliverySentBackLatitude}, ${delivery.deliverySentBackLongitude}`,
        });
      }

      if (delivery.deliveryLostLatitude && delivery.deliveryLostLongitude) {
        _rowsTracking.push({
          header: "분실완료 위치(위경도)",
          data: `${delivery.deliveryLostLatitude}, ${delivery.deliveryLostLongitude}`,
        });
      }

      if (delivery.agencyOrderId) {
        _rowsTracking.push({
          header: "QDN주문_일련번호",
          data: delivery.agencyOrderId,
        });

        _rowsTracking.push({
          header: "배송원성명(배송원연락처)",
          data: `${delivery.agencyDeliveryRiderName || ""}(${
            delivery.agencyDeliveryRiderMobile || ""
          })`,
        });
      }

      if (delivery.agencyNotReceivedImageLocation) {
        _rowsTracking.push({
          header: "기타 이미지",
          data: (
            <a
              href={delivery.agencyNotReceivedImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={delivery.agencyNotReceivedImageLocation}
                width="100%"
              />
            </a>
          ),
        });
      }

      setRowsDefault(_rowsDefault);
      setRowsSender(_rowsSender);
      setRowsReceiver(_rowsReceiver);
      setRowsProduct(_rowsProduct);
      setRowsTracking(_rowsTracking);
    } catch (e) {
      window.alert(e.message);
      closeModal();
    }
  });

  const fetchDeliveryStatusLogs = useCallback(async () => {
    try {
      const { rows } = await actions.fetchDeliveryStatusLogs(bookId);

      const _deliveryStatusLogs = rows?.sort((a, b) => {
        return a.createdAt < b.createdAt ? -1 : 1;
      });

      setRowsLog(_deliveryStatusLogs?.map((d) => new DeliveryStatusLog(d)));
    } catch (e) {
      window.alert(`배송 로그를 불러오는데 실패했습니다. ${e.message}`);
    }
  }, []);

  const TableRows = ({ row }) => {
    return (
      <tr>
        <Th>{row.header}</Th>
        <Td>
          {typeof row.data === "string"
            ? row.data.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
            : row.data}
        </Td>
      </tr>
    );
  };

  const LogRows = ({ row }) => {
    return (
      <LogItem>
        {row.isAgency ? "(배)" : ""} {row.renderCreatedAt()}{" "}
        {row.renderLogStatus()}
        {row.isAgency
          ? ""
          : `(${row.renderStatusBefore()}→${row.renderStatusAfter()})`}{" "}
        {row.logWriter}
        {row.agencyDeliveryRiderName
          ? `| 배달지정배송원명: ${row.agencyDeliveryRiderName}`
          : ""}
        {row.agencyFailedReason ? `| 실패사유: ${row.agencyFailedReason}` : ""}
      </LogItem>
    );
  };

  return (
    <Modal size="lg" {...props}>
      <Modal.Header>
        <Title>배송 정보</Title>
      </Modal.Header>
      <Modal.Body>
        <SubTitle>기본정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsDefault.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>송하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsSender.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>수하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsReceiver.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>물품정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsProduct.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>배송정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsTracking.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>배송로그</SubTitle>
        <LogsWrap>
          {rowsLog.map((row, i) => (
            <LogRows row={row} key={i} />
          ))}
        </LogsWrap>
      </Modal.Body>
    </Modal>
  );
};

export default DeliveryForRiderModal;
